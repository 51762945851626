import React, { useState, useEffect } from 'react';
import './CommonerDashboard.css';
import { FaHome, FaEnvelope, FaCog, FaScroll, FaUsers, FaBook, FaDiceD20 } from 'react-icons/fa';

const CommonerDashboard = ({ userData }) => {
  // State management
  const [activeTab, setActiveTab] = useState('home');
  const [requestStatus, setRequestStatus] = useState(null); // null, 'pending', 'approved', 'denied'
  const [requestReason, setRequestReason] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState('');
  const [expandedMessageId, setExpandedMessageId] = useState(null);

  // Mock data for demonstration purposes
  const mockMessages = [
    {
      id: 1,
      from: 'Dungeon Master',
      date: '2023-11-15T18:30:00',
      content: 'Greetings, traveler! The realm of FirstVeil welcomes you. As a Commoner, you have access to community resources and basic game information. Should you wish to embark on adventures, consider submitting a request to become an Adventurer. This will grant you access to quests, character creation, and the full experience of our world. Until then, explore the lands, learn our ways, and prepare for the journey ahead.',
      isAdmin: true
    },
    {
      id: 2,
      from: 'System',
      date: '2023-11-10T12:15:00',
      content: 'Your account has been successfully created. Welcome to FirstVeil! Please complete your profile to enhance your experience. If you have any questions, feel free to reach out to our support team via the contact form in the Settings tab.',
      isSystem: true
    }
  ];

  // Handlers
  const handleTabChange = (tab) => {
    setActiveTab(tab);
    // Close any expanded messages when changing tabs
    setExpandedMessageId(null);
  };

  const handleReasonChange = (e) => {
    setRequestReason(e.target.value);
    if (error) setError('');
  };

  const handleSubmitRequest = (e) => {
    e.preventDefault();
    if (requestReason.trim().length < 20) {
      setError('Please provide a more detailed reason (at least 20 characters)');
      return;
    }

    // In a real app, this would send the request to the server
    console.log('Submitting request with reason:', requestReason);
    
    // Mock API call
    setTimeout(() => {
      setSubmitted(true);
      setRequestStatus('pending');
      // Reset form
      setRequestReason('');
    }, 1000);
  };

  const toggleMessageExpand = (id) => {
    setExpandedMessageId(expandedMessageId === id ? null : id);
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <div className="commoner-dashboard">
      {/* Mobile Navigation */}
      <nav className="mobile-nav">
        <button 
          className={`nav-tab ${activeTab === 'home' ? 'active' : ''}`} 
          onClick={() => handleTabChange('home')}
        >
          <FaHome className="tab-icon" />
          <span className="tab-label">Home</span>
        </button>
        <button 
          className={`nav-tab ${activeTab === 'messages' ? 'active' : ''}`} 
          onClick={() => handleTabChange('messages')}
        >
          <FaEnvelope className="tab-icon" />
          <span className="tab-label">Messages</span>
          {mockMessages.length > 0 && <span className="notification-badge">{mockMessages.length}</span>}
        </button>
        <button 
          className={`nav-tab ${activeTab === 'settings' ? 'active' : ''}`} 
          onClick={() => handleTabChange('settings')}
        >
          <FaCog className="tab-icon" />
          <span className="tab-label">Settings</span>
        </button>
      </nav>

      {/* Main Dashboard Content */}
      <main className="dashboard-content">
        {/* Home Tab */}
        {activeTab === 'home' && (
          <div className="view-container">
            <h1 className="view-title">Commoner Dashboard</h1>
            
            {/* Welcome Section */}
            <section className="dashboard-section">
              <div className="welcome-card">
                <div className="welcome-header">
                  <h2>Welcome, {userData?.name || 'Traveler'}</h2>
                  <span className="role-badge">Commoner</span>
                </div>
                <p className="welcome-message">
                  As a Commoner of FirstVeil, you have access to basic resources and community features.
                  Explore the world lore, connect with other players, and discover the adventure that awaits you.
                </p>
              </div>
            </section>

            {/* Role Upgrade Request Section */}
            <section className="dashboard-section">
              <h2 className="section-title">Become an Adventurer</h2>
              
              {!requestStatus && !submitted ? (
                <div className="request-card">
                  <div className="request-info">
                    <h3>Why Become an Adventurer?</h3>
                    <p>Upgrade your account to access exclusive features:</p>
                    <ul className="benefits-list">
                      <li>Create and customize your character</li>
                      <li>Join quests and adventures</li>
                      <li>Interact with other adventurers</li>
                      <li>Earn rewards and progress in the game</li>
                      <li>Access exclusive areas and content</li>
                    </ul>
                  </div>
                  <div className="request-form">
                    <form onSubmit={handleSubmitRequest}>
                      {error && <div className="error-message">{error}</div>}
                      <div className="form-group">
                        <label htmlFor="requestReason">Why do you want to become an Adventurer?</label>
                        <textarea 
                          id="requestReason"
                          value={requestReason}
                          onChange={handleReasonChange}
                          placeholder="Share your interest in fantasy role-playing, your previous experience, or why you're excited to join our world..."
                          required
                        ></textarea>
                      </div>
                      <button type="submit" className="submit-button">Submit Request</button>
                    </form>
                  </div>
                </div>
              ) : (
                <div className={`status-card ${requestStatus}`}>
                  {requestStatus === 'pending' && (
                    <>
                      <div className="status-icon">⏳</div>
                      <h3>Request Pending</h3>
                      <p>Your adventurer application is being reviewed by our Game Masters. This process usually takes 1-2 days. We'll notify you once a decision has been made.</p>
                    </>
                  )}
                  {requestStatus === 'approved' && (
                    <>
                      <div className="status-icon">✅</div>
                      <h3>Request Approved!</h3>
                      <p>Congratulations! You are now an Adventurer. Your dashboard will be updated shortly with new features. Prepare for epic quests and adventures!</p>
                    </>
                  )}
                  {requestStatus === 'denied' && (
                    <>
                      <div className="status-icon">❌</div>
                      <h3>Request Denied</h3>
                      <p>Unfortunately, your request was not approved at this time. Please review our community guidelines and consider reapplying in 14 days.</p>
                      <button className="submit-button" onClick={() => setRequestStatus(null)}>Apply Again</button>
                    </>
                  )}
                </div>
              )}
            </section>

            {/* Community Resources Section */}
            <section className="dashboard-section">
              <h2 className="section-title">Community Resources</h2>
              <div className="resources-grid">
                <a href="#" className="resource-card">
                  <FaScroll className="resource-icon" />
                  <h3>Game Rules</h3>
                  <p>Learn the basics of gameplay and character progression.</p>
                </a>
                <a href="#" className="resource-card">
                  <FaUsers className="resource-icon" />
                  <h3>Find Groups</h3>
                  <p>Connect with other players to form parties.</p>
                </a>
                <a href="#" className="resource-card">
                  <FaBook className="resource-icon" />
                  <h3>Tutorials</h3>
                  <p>Step-by-step guides for new players.</p>
                </a>
                <a href="#" className="resource-card">
                  <FaDiceD20 className="resource-icon" />
                  <h3>Game Tools</h3>
                  <p>Helpful calculators and references.</p>
                </a>
              </div>
            </section>
          </div>
        )}

        {/* Messages Tab */}
        {activeTab === 'messages' && (
          <div className="view-container">
            <h1 className="view-title">Messages</h1>
            
            <div className="messages-list">
              {mockMessages.length > 0 ? (
                mockMessages.map(message => (
                  <div 
                    key={message.id} 
                    className={`message-card ${expandedMessageId === message.id ? 'expanded' : ''}`}
                  >
                    <div 
                      className="message-header" 
                      onClick={() => toggleMessageExpand(message.id)}
                    >
                      <div className="message-meta">
                        <div className="message-from">{message.from}</div>
                        <div className="message-date">{formatDate(message.date)}</div>
                      </div>
                      <div className="expand-toggle">
                        {expandedMessageId === message.id ? '−' : '+'}
                      </div>
                    </div>
                    <div className={`message-content ${expandedMessageId === message.id ? 'expanded' : ''}`}>
                      <p>{message.content}</p>
                    </div>
                  </div>
                ))
              ) : (
                <div className="empty-state">
                  <p>You have no messages yet.</p>
                </div>
              )}
            </div>
          </div>
        )}

        {/* Settings Tab */}
        {activeTab === 'settings' && (
          <div className="view-container">
            <h1 className="view-title">Settings</h1>
            
            {/* Personal Information */}
            <section className="dashboard-section">
              <h2 className="section-title">Personal Information</h2>
              <div className="settings-card">
                <div className="settings-form">
                  <div className="form-group">
                    <label htmlFor="username">Username</label>
                    <input 
                      type="text" 
                      id="username" 
                      value={userData?.username || 'user123'} 
                      disabled 
                    />
                    <small>Your username cannot be changed.</small>
                  </div>
                  <div className="form-group">
                    <label htmlFor="fullName">Full Name</label>
                    <input 
                      type="text" 
                      id="fullName" 
                      placeholder="Enter your full name" 
                      defaultValue={userData?.name || ''} 
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input 
                      type="email" 
                      id="email" 
                      placeholder="Enter your email" 
                      defaultValue={userData?.email || ''} 
                    />
                  </div>
                  <div className="form-actions">
                    <button className="action-button">Save Changes</button>
                  </div>
                </div>
              </div>
            </section>
            
            {/* Password Management */}
            <section className="dashboard-section">
              <h2 className="section-title">Password Management</h2>
              <div className="settings-card">
                <div className="settings-form">
                  <div className="form-group">
                    <label htmlFor="currentPassword">Current Password</label>
                    <input 
                      type="password" 
                      id="currentPassword" 
                      placeholder="Enter your current password" 
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="newPassword">New Password</label>
                    <input 
                      type="password" 
                      id="newPassword" 
                      placeholder="Enter a new password" 
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="confirmPassword">Confirm New Password</label>
                    <input 
                      type="password" 
                      id="confirmPassword" 
                      placeholder="Confirm your new password" 
                    />
                  </div>
                  <div className="form-actions">
                    <button className="action-button">Update Password</button>
                  </div>
                </div>
              </div>
            </section>
            
            {/* Notification Preferences */}
            <section className="dashboard-section">
              <h2 className="section-title">Notification Preferences</h2>
              <div className="settings-card">
                <div className="settings-form">
                  <div className="checkbox-group">
                    <label>
                      <input type="checkbox" defaultChecked />
                      Email notifications for new messages
                    </label>
                  </div>
                  <div className="checkbox-group">
                    <label>
                      <input type="checkbox" defaultChecked />
                      Email notifications for status updates
                    </label>
                  </div>
                  <div className="checkbox-group">
                    <label>
                      <input type="checkbox" defaultChecked />
                      Newsletter and game updates
                    </label>
                  </div>
                  <div className="form-actions">
                    <button className="action-button">Save Preferences</button>
                  </div>
                </div>
              </div>
            </section>
          </div>
        )}
      </main>
    </div>
  );
};

export default CommonerDashboard; 