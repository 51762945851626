import React, { useState, useRef, useEffect } from 'react';
import './AdventurerDashboard.css';
import { FaHome, FaEnvelope, FaUser, FaDiceD20, FaScroll, FaSuitcase, FaDiscord } from 'react-icons/fa';

const AdventurerDashboard = ({ userData = {} }) => {
  // Main state
  const [activeTab, setActiveTab] = useState('overview');
  const [expandedMessage, setExpandedMessage] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  
  // Avatar upload state
  const [isUploading, setIsUploading] = useState(false);
  const [uploadError, setUploadError] = useState('');
  const [avatarPreview, setAvatarPreview] = useState(userData.avatar || '');
  
  // References
  const fileInputRef = useRef(null);
  
  // Character sheet state
  const [characterData, setCharacterData] = useState({
    // Basic info
    characterName: userData.characterName || '',
    class: userData.class || '',
    level: userData.level || '',
    background: userData.background || '',
    playerName: userData.name || '',
    race: userData.race || '',
    alignment: userData.alignment || '',
    experiencePoints: userData.experiencePoints || '',
    
    // Ability scores
    abilityScores: {
      strength: userData.abilityScores?.strength || '',
      dexterity: userData.abilityScores?.dexterity || '',
      constitution: userData.abilityScores?.constitution || '',
      intelligence: userData.abilityScores?.intelligence || '',
      wisdom: userData.abilityScores?.wisdom || '',
      charisma: userData.abilityScores?.charisma || ''
    },
    
    // Skills
    skills: {
      acrobatics: userData.skills?.acrobatics || false,
      animalHandling: userData.skills?.animalHandling || false,
      arcana: userData.skills?.arcana || false,
      athletics: userData.skills?.athletics || false,
      deception: userData.skills?.deception || false,
      history: userData.skills?.history || false,
      insight: userData.skills?.insight || false,
      intimidation: userData.skills?.intimidation || false,
      investigation: userData.skills?.investigation || false,
      medicine: userData.skills?.medicine || false,
      nature: userData.skills?.nature || false,
      perception: userData.skills?.perception || false,
      performance: userData.skills?.performance || false,
      persuasion: userData.skills?.persuasion || false,
      religion: userData.skills?.religion || false,
      sleightOfHand: userData.skills?.sleightOfHand || false,
      stealth: userData.skills?.stealth || false,
      survival: userData.skills?.survival || false
    },
    
    // Combat stats
    armorClass: userData.armorClass || '',
    initiative: userData.initiative || '',
    speed: userData.speed || '',
    hitPointMaximum: userData.hitPointMaximum || '',
    currentHitPoints: userData.currentHitPoints || '',
    temporaryHitPoints: userData.temporaryHitPoints || '',
    hitDice: userData.hitDice || '',
    
    // Saving throws
    savingThrows: {
      strength: userData.savingThrows?.strength || false,
      dexterity: userData.savingThrows?.dexterity || false,
      constitution: userData.savingThrows?.constitution || false,
      intelligence: userData.savingThrows?.intelligence || false,
      wisdom: userData.savingThrows?.wisdom || false,
      charisma: userData.savingThrows?.charisma || false
    },
    
    // Equipment
    equipment: userData.equipment || '',
    
    // Features & traits
    featuresAndTraits: userData.featuresAndTraits || '',
    
    // Additional sections
    proficienciesAndLanguages: userData.proficienciesAndLanguages || '',
    personality: userData.personality || '',
    ideals: userData.ideals || '',
    bonds: userData.bonds || '',
    flaws: userData.flaws || ''
  });

  // Fetch character data when component mounts
  useEffect(() => {
    const fetchCharacterData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL || 'https://api.firstveil.com'}/api/users/character`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });

        if (!response.ok) {
          // If character data doesn't exist yet, that's okay
          if (response.status === 404) {
            console.log('No character data found for this user yet');
            return;
          }
          throw new Error('Failed to fetch character data');
        }

        const data = await response.json();
        setCharacterData(data);
      } catch (error) {
        console.error('Error fetching character data:', error);
        // Don't show an error to the user, just use the initial state
      }
    };

    // Only fetch if we have a token (user is logged in)
    if (localStorage.getItem('token')) {
      fetchCharacterData();
    }
  }, []);

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
    setExpandedMessage(null);
  };

  const toggleMessageExpand = (msgId) => {
    setExpandedMessage(expandedMessage === msgId ? null : msgId);
  };

  const handleAvatarClick = () => {
    fileInputRef.current.click();
  };

  const handleAvatarUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;
    
    // Check file size and type
    if (file.size > 5 * 1024 * 1024) {
      setUploadError('File size too large (max 5MB)');
      return;
    }
    
    if (!file.type.startsWith('image/')) {
      setUploadError('Please upload an image file');
      return;
    }
    
    setIsUploading(true);
    setUploadError('');
    
    try {
      // Create a preview
      const reader = new FileReader();
      reader.onload = (event) => {
        setAvatarPreview(event.target.result);
      };
      reader.readAsDataURL(file);
      
      // In a real app, you would upload the file to a server here
      // const formData = new FormData();
      // formData.append('avatar', file);
      // const response = await fetch('/api/avatar-upload', {
      //   method: 'POST',
      //   body: formData
      // });
      
      // if (!response.ok) throw new Error('Failed to upload avatar');
      
      // Simulate upload delay for demonstration
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      // Success handling would go here
    } catch (error) {
      setUploadError('Failed to upload: ' + error.message);
    } finally {
      setIsUploading(false);
    }
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const getMessagePreview = (content) => {
    // Strip HTML tags for preview
    const plainText = content.replace(/<[^>]*>/g, '');
    
    // Limit to ~100 characters
    if (plainText.length <= 100) return plainText;
    
    return plainText.substring(0, 97) + '...';
  };

  const getInitials = (name) => {
    if (!name) return 'A';
    return name
      .split(' ')
      .map(part => part[0])
      .join('')
      .toUpperCase()
      .substring(0, 2);
  };

  const handleInputChange = (e, section, field) => {
    if (section) {
      setCharacterData({
        ...characterData,
        [section]: {
          ...characterData[section],
          [field]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
        }
      });
    } else {
      setCharacterData({
        ...characterData,
        [field]: e.target.value
      });
    }
  };

  const handleSaveCharacter = async () => {
    try {
      setIsEditing(false);
      
      // Show loading state (optional - could add a loading state if desired)
      
      // Make API call to save character data
      const response = await fetch(`${process.env.REACT_APP_API_URL || 'https://api.firstveil.com'}/api/users/character`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}` // Assuming you store the auth token in localStorage
        },
        body: JSON.stringify(characterData)
      });
      
      if (!response.ok) {
        throw new Error('Failed to save character data');
      }
      
      // Successfully saved
      console.log('Character data saved successfully');
      
      // Optional: You could update the userData prop here if using a state management system
      // For example with Redux: dispatch(updateUserData({ ...userData, ...characterData }));
      
    } catch (error) {
      console.error('Error saving character data:', error);
      // You could set an error state here to display a message to the user
      setIsEditing(true); // Keep editing mode on if there was an error
      alert('Failed to save character data. Please try again.');
    }
  };

  const calculateModifier = (score) => {
    if (!score) return '';
    const modifier = Math.floor((parseInt(score) - 10) / 2);
    return modifier >= 0 ? `+${modifier}` : `${modifier}`;
  };

  return (
    <div className="adventurer-dashboard">
      {/* Mobile Navigation */}
      <nav className="mobile-nav">
        <button 
          className={`nav-tab ${activeTab === 'overview' ? 'active' : ''}`} 
          onClick={() => handleTabClick('overview')}
        >
          <FaHome className="tab-icon" />
          <span className="tab-label">Home</span>
        </button>
        <button 
          className={`nav-tab ${activeTab === 'messages' ? 'active' : ''}`} 
          onClick={() => handleTabClick('messages')}
        >
          <FaEnvelope className="tab-icon" />
          <span className="tab-label">Messages</span>
        </button>
        <button 
          className={`nav-tab ${activeTab === 'character' ? 'active' : ''}`} 
          onClick={() => handleTabClick('character')}
        >
          <FaUser className="tab-icon" />
          <span className="tab-label">Character</span>
        </button>
        <button 
          className={`nav-tab ${activeTab === 'inventory' ? 'active' : ''}`} 
          onClick={() => handleTabClick('inventory')}
        >
          <FaSuitcase className="tab-icon" />
          <span className="tab-label">Inventory</span>
        </button>
      </nav>

      {/* Main Dashboard Content */}
      <main className="dashboard-content">
        {/* Overview Tab */}
        {activeTab === 'overview' && (
          <div className="view-container">
            <h1 className="view-title">Adventurer Dashboard</h1>
            
            {/* Messages Section */}
            <section className="dashboard-section">
              <h2 className="section-title">Recent Messages</h2>
              <div className="messages-list">
                {userData.messages && userData.messages.length > 0 ? (
                  userData.messages.slice(0, 3).map(message => (
                    <div 
                      key={message.id} 
                      className={`message-card ${expandedMessage === message.id ? 'expanded' : ''}`}
                    >
                      <div 
                        className="message-header" 
                        onClick={() => toggleMessageExpand(message.id)}
                      >
                        <div className="message-meta">
                          <h3 className="message-from">{message.from}</h3>
                          <span className="message-date">{formatDate(message.date)}</span>
                        </div>
                        <button className="expand-toggle" aria-label={expandedMessage === message.id ? "Collapse message" : "Expand message"}>
                          {expandedMessage === message.id ? '−' : '+'}
                        </button>
                      </div>
                      
                      {expandedMessage !== message.id && (
                        <div className="message-preview" onClick={() => toggleMessageExpand(message.id)}>
                          {getMessagePreview(message.content)}
                        </div>
                      )}
                      
                      <div className={`message-content ${expandedMessage === message.id ? 'expanded' : ''}`} dangerouslySetInnerHTML={{ __html: message.content }} />
                    </div>
                  ))
                ) : (
                  <div className="empty-state">
                    <p>You have no messages yet.</p>
                  </div>
                )}
              </div>
            </section>
            
            {/* Last Session Recap */}
            <section className="dashboard-section">
              <h2 className="section-title">Last Session</h2>
              {userData.lastSession ? (
                <div className="session-recap-card">
                  <div className="session-recap-header">
                    <h3 className="session-title">{userData.lastSession.title}</h3>
                    <span className="session-date">{formatDate(userData.lastSession.date)}</span>
                  </div>
                  <div className="session-summary" dangerouslySetInnerHTML={{ __html: userData.lastSession.summary }} />
                </div>
              ) : (
                <div className="empty-state">
                  <p>No recent sessions.</p>
                </div>
              )}
            </section>
            
            {/* Quick Links */}
            <section className="dashboard-section">
              <h2 className="section-title">Quick Links</h2>
              <div className="quick-links-grid">
                <a href="#" className="quick-link-card" onClick={(e) => { e.preventDefault(); handleTabClick('character'); }}>
                  <FaUser className="quick-link-icon" />
                  <h3 className="quick-link-label">Character Sheet</h3>
                </a>
                <a href="#" className="quick-link-card" onClick={(e) => { e.preventDefault(); handleTabClick('inventory'); }}>
                  <FaSuitcase className="quick-link-icon" />
                  <h3 className="quick-link-label">Inventory</h3>
                </a>
                <a href="http://47.7.21.116:30000/join" className="quick-link-card" target="_blank" rel="noopener noreferrer">
                  <FaDiceD20 className="quick-link-icon" />
                  <h3 className="quick-link-label">FoundryVTT</h3>
                </a>
                <a href="https://discord.gg/SGMCCwsd" className="quick-link-card" target="_blank" rel="noopener noreferrer">
                  <FaDiscord className="quick-link-icon" />
                  <h3 className="quick-link-label">Discord</h3>
                </a>
              </div>
            </section>
          </div>
        )}

        {/* Messages Tab */}
        {activeTab === 'messages' && (
          <div className="view-container">
            <h1 className="view-title">Messages</h1>
            
            <div className="messages-list">
              {userData.messages && userData.messages.length > 0 ? (
                userData.messages.map(message => (
                  <div 
                    key={message.id} 
                    className={`message-card ${expandedMessage === message.id ? 'expanded' : ''}`}
                  >
                    <div 
                      className="message-header" 
                      onClick={() => toggleMessageExpand(message.id)}
                    >
                      <div className="message-meta">
                        <h3 className="message-from">{message.from}</h3>
                        <span className="message-date">{formatDate(message.date)}</span>
                      </div>
                      <button className="expand-toggle" aria-label={expandedMessage === message.id ? "Collapse message" : "Expand message"}>
                        {expandedMessage === message.id ? '−' : '+'}
                      </button>
                    </div>
                    
                    {expandedMessage !== message.id && (
                      <div className="message-preview" onClick={() => toggleMessageExpand(message.id)}>
                        {getMessagePreview(message.content)}
                      </div>
                    )}
                    
                    <div className={`message-content ${expandedMessage === message.id ? 'expanded' : ''}`} dangerouslySetInnerHTML={{ __html: message.content }} />
                  </div>
                ))
              ) : (
                <div className="empty-state">
                  <p>You have no messages yet.</p>
                </div>
              )}
            </div>
          </div>
        )}

        {/* Character Tab */}
        {activeTab === 'character' && (
          <div className="view-container character-sheet">
            <div className="character-sheet-header">
              <h1 className="view-title">Character Sheet</h1>
              {!isEditing ? (
                <button 
                  className="action-button primary"
                  onClick={() => setIsEditing(true)}
                >
                  Edit Character
                </button>
              ) : (
                <div className="edit-actions">
                  <button 
                    className="action-button secondary"
                    onClick={() => setIsEditing(false)}
                  >
                    Cancel
                  </button>
                  <button 
                    className="action-button primary"
                    onClick={handleSaveCharacter}
                  >
                    Save Changes
                  </button>
                </div>
              )}
            </div>
            
            <div className="character-sheet-container">
              {/* Character Avatar and Basic Info */}
              <div className="character-header-section">
                <div className="avatar-section">
                  <div className="character-avatar" onClick={isEditing ? handleAvatarClick : undefined}>
                    {avatarPreview ? (
                      <img src={avatarPreview} alt="Character avatar" />
                    ) : (
                      <div className="avatar-placeholder">
                        {getInitials(characterData.characterName)}
                      </div>
                    )}
                    {isEditing && (
                      <div className="avatar-overlay">
                        <span>Change Avatar</span>
                      </div>
                    )}
                    <input 
                      type="file" 
                      ref={fileInputRef} 
                      onChange={handleAvatarUpload} 
                      style={{ display: 'none' }} 
                      accept="image/*"
                    />
                  </div>
                  {uploadError && <div className="upload-error">{uploadError}</div>}
                </div>
                
                <div className="basic-info-section">
                  <div className="info-group">
                    <label htmlFor="characterName">Character Name</label>
                    <input
                      type="text"
                      id="characterName"
                      value={characterData.characterName}
                      onChange={(e) => handleInputChange(e, null, 'characterName')}
                      disabled={!isEditing}
                    />
                  </div>
                  
                  <div className="info-row">
                    <div className="info-group">
                      <label htmlFor="class">Class</label>
                      <input
                        type="text"
                        id="class"
                        value={characterData.class}
                        onChange={(e) => handleInputChange(e, null, 'class')}
                        disabled={!isEditing}
                      />
                    </div>
                    
                    <div className="info-group">
                      <label htmlFor="level">Level</label>
                      <input
                        type="text"
                        id="level"
                        value={characterData.level}
                        onChange={(e) => handleInputChange(e, null, 'level')}
                        disabled={!isEditing}
                      />
                    </div>
                  </div>
                  
                  <div className="info-group">
                    <label htmlFor="background">Background</label>
                    <input
                      type="text"
                      id="background"
                      value={characterData.background}
                      onChange={(e) => handleInputChange(e, null, 'background')}
                      disabled={!isEditing}
                    />
                  </div>
                  
                  <div className="info-group">
                    <label htmlFor="playerName">Player Name</label>
                    <input
                      type="text"
                      id="playerName"
                      value={characterData.playerName}
                      onChange={(e) => handleInputChange(e, null, 'playerName')}
                      disabled={!isEditing}
                    />
                  </div>
                  
                  <div className="info-row">
                    <div className="info-group">
                      <label htmlFor="race">Race</label>
                      <input
                        type="text"
                        id="race"
                        value={characterData.race}
                        onChange={(e) => handleInputChange(e, null, 'race')}
                        disabled={!isEditing}
                      />
                    </div>
                    
                    <div className="info-group">
                      <label htmlFor="alignment">Alignment</label>
                      <input
                        type="text"
                        id="alignment"
                        value={characterData.alignment}
                        onChange={(e) => handleInputChange(e, null, 'alignment')}
                        disabled={!isEditing}
                      />
                    </div>
                  </div>
                  
                  <div className="info-group">
                    <label htmlFor="experiencePoints">Experience Points</label>
                    <input
                      type="text"
                      id="experiencePoints"
                      value={characterData.experiencePoints}
                      onChange={(e) => handleInputChange(e, null, 'experiencePoints')}
                      disabled={!isEditing}
                    />
                  </div>
                </div>
              </div>
              
              {/* Main Character Sheet Grid */}
              <div className="character-sheet-grid">
                {/* Ability Scores Section */}
                <div className="ability-scores-section">
                  <h3 className="section-label">Ability Scores</h3>
                  
                  <div className="ability-scores-container">
                    {Object.entries(characterData.abilityScores).map(([ability, score]) => (
                      <div key={ability} className="ability-score-box">
                        <label htmlFor={ability}>{ability.charAt(0).toUpperCase() + ability.slice(1)}</label>
                        <input
                          type="number"
                          id={ability}
                          value={score}
                          onChange={(e) => handleInputChange(e, 'abilityScores', ability)}
                          disabled={!isEditing}
                        />
                        <div className="ability-modifier">{calculateModifier(score)}</div>
                      </div>
                    ))}
                  </div>
                </div>
                
                {/* Saving Throws Section */}
                <div className="saving-throws-section">
                  <h3 className="section-label">Saving Throws</h3>
                  
                  <div className="saving-throws-container">
                    {Object.entries(characterData.savingThrows).map(([ability, isProficient]) => (
                      <div key={ability} className="saving-throw-item">
                        <input
                          type="checkbox"
                          id={`save-${ability}`}
                          checked={isProficient}
                          onChange={(e) => handleInputChange(e, 'savingThrows', ability)}
                          disabled={!isEditing}
                        />
                        <label htmlFor={`save-${ability}`}>
                          {ability.charAt(0).toUpperCase() + ability.slice(1)}
                        </label>
                        <div className="save-modifier">
                          {isProficient 
                            ? calculateModifier(characterData.abilityScores[ability]) 
                            : calculateModifier(characterData.abilityScores[ability])}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                
                {/* Skills Section */}
                <div className="skills-section">
                  <h3 className="section-label">Skills</h3>
                  
                  <div className="skills-container">
                    {Object.entries(characterData.skills).map(([skill, isProficient]) => {
                      // Map skills to their corresponding ability
                      const abilityMap = {
                        acrobatics: 'dexterity',
                        animalHandling: 'wisdom',
                        arcana: 'intelligence',
                        athletics: 'strength',
                        deception: 'charisma',
                        history: 'intelligence',
                        insight: 'wisdom',
                        intimidation: 'charisma',
                        investigation: 'intelligence',
                        medicine: 'wisdom',
                        nature: 'intelligence',
                        perception: 'wisdom',
                        performance: 'charisma',
                        persuasion: 'charisma',
                        religion: 'intelligence',
                        sleightOfHand: 'dexterity',
                        stealth: 'dexterity',
                        survival: 'wisdom'
                      };

                      // Format skill name (e.g., "sleightOfHand" -> "Sleight of Hand")
                      const formatSkillName = (name) => {
                        return name
                          .replace(/([A-Z])/g, ' $1')
                          .replace(/^./, str => str.toUpperCase());
                      };

                      return (
                        <div key={skill} className="skill-item">
                          <input
                            type="checkbox"
                            id={`skill-${skill}`}
                            checked={isProficient}
                            onChange={(e) => handleInputChange(e, 'skills', skill)}
                            disabled={!isEditing}
                          />
                          <label htmlFor={`skill-${skill}`}>
                            {formatSkillName(skill)} ({abilityMap[skill].charAt(0).toUpperCase()})
                          </label>
                          <div className="skill-modifier">
                            {calculateModifier(characterData.abilityScores[abilityMap[skill]])}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                
                {/* Combat Stats Section */}
                <div className="combat-stats-section">
                  <h3 className="section-label">Combat</h3>
                  
                  <div className="combat-stats-container">
                    <div className="combat-stat-group">
                      <label htmlFor="armorClass">Armor Class</label>
                      <input
                        type="text"
                        id="armorClass"
                        value={characterData.armorClass}
                        onChange={(e) => handleInputChange(e, null, 'armorClass')}
                        disabled={!isEditing}
                      />
                    </div>
                    
                    <div className="combat-stat-group">
                      <label htmlFor="initiative">Initiative</label>
                      <input
                        type="text"
                        id="initiative"
                        value={characterData.initiative}
                        onChange={(e) => handleInputChange(e, null, 'initiative')}
                        disabled={!isEditing}
                      />
                    </div>
                    
                    <div className="combat-stat-group">
                      <label htmlFor="speed">Speed</label>
                      <input
                        type="text"
                        id="speed"
                        value={characterData.speed}
                        onChange={(e) => handleInputChange(e, null, 'speed')}
                        disabled={!isEditing}
                      />
                    </div>
                    
                    <div className="combat-stat-group hit-points">
                      <label htmlFor="hitPointMaximum">Hit Point Maximum</label>
                      <input
                        type="text"
                        id="hitPointMaximum"
                        value={characterData.hitPointMaximum}
                        onChange={(e) => handleInputChange(e, null, 'hitPointMaximum')}
                        disabled={!isEditing}
                      />
                      
                      <label htmlFor="currentHitPoints">Current Hit Points</label>
                      <input
                        type="text"
                        id="currentHitPoints"
                        value={characterData.currentHitPoints}
                        onChange={(e) => handleInputChange(e, null, 'currentHitPoints')}
                        disabled={!isEditing}
                      />
                      
                      <label htmlFor="temporaryHitPoints">Temporary Hit Points</label>
                      <input
                        type="text"
                        id="temporaryHitPoints"
                        value={characterData.temporaryHitPoints}
                        onChange={(e) => handleInputChange(e, null, 'temporaryHitPoints')}
                        disabled={!isEditing}
                      />
                    </div>
                    
                    <div className="combat-stat-group">
                      <label htmlFor="hitDice">Hit Dice</label>
                      <input
                        type="text"
                        id="hitDice"
                        value={characterData.hitDice}
                        onChange={(e) => handleInputChange(e, null, 'hitDice')}
                        disabled={!isEditing}
                      />
                    </div>
                  </div>
                </div>
                
                {/* Character Features and Traits */}
                <div className="features-section">
                  <h3 className="section-label">Features & Traits</h3>
                  <textarea
                    id="featuresAndTraits"
                    value={characterData.featuresAndTraits}
                    onChange={(e) => handleInputChange(e, null, 'featuresAndTraits')}
                    disabled={!isEditing}
                    placeholder="Features, traits, and abilities..."
                  ></textarea>
                </div>
                
                {/* Proficiencies & Languages */}
                <div className="proficiencies-section">
                  <h3 className="section-label">Proficiencies & Languages</h3>
                  <textarea
                    id="proficienciesAndLanguages"
                    value={characterData.proficienciesAndLanguages}
                    onChange={(e) => handleInputChange(e, null, 'proficienciesAndLanguages')}
                    disabled={!isEditing}
                    placeholder="Weapons, armor, tools, languages..."
                  ></textarea>
                </div>
                
                {/* Personality Section */}
                <div className="personality-section">
                  <h3 className="section-label">Character Details</h3>
                  
                  <div className="personality-container">
                    <div className="personality-trait">
                      <label htmlFor="personality">Personality Traits</label>
                      <textarea
                        id="personality"
                        value={characterData.personality}
                        onChange={(e) => handleInputChange(e, null, 'personality')}
                        disabled={!isEditing}
                        placeholder="Character traits..."
                      ></textarea>
                    </div>
                    
                    <div className="personality-trait">
                      <label htmlFor="ideals">Ideals</label>
                      <textarea
                        id="ideals"
                        value={characterData.ideals}
                        onChange={(e) => handleInputChange(e, null, 'ideals')}
                        disabled={!isEditing}
                        placeholder="Character ideals..."
                      ></textarea>
                    </div>
                    
                    <div className="personality-trait">
                      <label htmlFor="bonds">Bonds</label>
                      <textarea
                        id="bonds"
                        value={characterData.bonds}
                        onChange={(e) => handleInputChange(e, null, 'bonds')}
                        disabled={!isEditing}
                        placeholder="Character bonds..."
                      ></textarea>
                    </div>
                    
                    <div className="personality-trait">
                      <label htmlFor="flaws">Flaws</label>
                      <textarea
                        id="flaws"
                        value={characterData.flaws}
                        onChange={(e) => handleInputChange(e, null, 'flaws')}
                        disabled={!isEditing}
                        placeholder="Character flaws..."
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        
        {/* Inventory Tab */}
        {activeTab === 'inventory' && (
          <div className="view-container">
            <div className="inventory-header">
              <h1 className="view-title">Inventory</h1>
              {!isEditing ? (
                <button 
                  className="action-button primary"
                  onClick={() => setIsEditing(true)}
                >
                  Edit Inventory
                </button>
              ) : (
                <div className="edit-actions">
                  <button 
                    className="action-button secondary"
                    onClick={() => setIsEditing(false)}
                  >
                    Cancel
                  </button>
                  <button 
                    className="action-button primary"
                    onClick={handleSaveCharacter}
                  >
                    Save Changes
                  </button>
                </div>
              )}
            </div>
            
            <div className="inventory-container">
              <div className="currency-section">
                <h3 className="section-label">Currency</h3>
                <div className="currency-container">
                  {['Copper', 'Silver', 'Electrum', 'Gold', 'Platinum'].map(currency => (
                    <div key={currency} className="currency-item">
                      <label htmlFor={`currency-${currency.toLowerCase()}`}>{currency}</label>
                      <input
                        type="text"
                        id={`currency-${currency.toLowerCase()}`}
                        value={userData[`currency${currency}`] || ''}
                        disabled={!isEditing}
                      />
                    </div>
                  ))}
                </div>
              </div>
              
              <div className="equipment-section">
                <h3 className="section-label">Equipment</h3>
                <textarea
                  className="equipment-textarea"
                  value={characterData.equipment}
                  onChange={(e) => handleInputChange(e, null, 'equipment')}
                  disabled={!isEditing}
                  placeholder="Weapons, armor, and other equipment..."
                ></textarea>
              </div>
            </div>
          </div>
        )}
      </main>
    </div>
  );
};

export default AdventurerDashboard; 