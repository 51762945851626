import React, { useState, useEffect } from 'react';
import './AdminDashboard.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// Dummy API URL - replace with your actual API configuration
const API_URL = process.env.REACT_APP_API_URL || 'https://api.firstveil.com';

const AdminDashboard = () => {
  // Main app state
  const [activeView, setActiveView] = useState('users'); // 'users', 'requests', 'notifications'
  const [users, setUsers] = useState([]);
  const [roleRequests, setRoleRequests] = useState([]);
  const [notifications, setNotifications] = useState([]);
  
  // Selected user context
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [isViewingUserDetails, setIsViewingUserDetails] = useState(false);
  const [isComposingMessage, setIsComposingMessage] = useState(false);
  
  // UI state
  const [isLoading, setIsLoading] = useState({
    users: true,
    requests: true,
    notifications: true
  });
  const [errorMessages, setErrorMessages] = useState({
    users: '',
    requests: '',
    notifications: '',
    message: ''
  });
  
  // Search and filters
  const [searchQuery, setSearchQuery] = useState('');
  
  // Message composition
  const [messageContent, setMessageContent] = useState('');
  
  // Modals
  const [activeModal, setActiveModal] = useState(null); // 'delete', 'password', 'approve', 'deny'
  const [modalTargetId, setModalTargetId] = useState(null);

  // User character sheet editing state
  const [isEditingCharacter, setIsEditingCharacter] = useState(false);
  const [characterData, setCharacterData] = useState(null);

  // Fetch data when component mounts
  useEffect(() => {
    fetchUsers();
    fetchRoleRequests();
    fetchNotifications();
    
    // Set up polling for notifications
    const notificationInterval = setInterval(() => {
      fetchNotifications(true); // silent update
    }, 60000);
    
    return () => clearInterval(notificationInterval);
  }, []);

  // Data fetching functions
  const fetchUsers = async () => {
    setIsLoading(prev => ({ ...prev, users: true }));
    setErrorMessages(prev => ({ ...prev, users: '' }));
    
    try {
      // In a real app, replace with actual API call
      // This is simulated data for development purposes
      const mockUsers = [
        { id: 1, displayName: 'Aragorn', email: 'aragorn@firstveil.com', role: 'admin', createdAt: '2023-01-15', lastLogin: '2023-06-22' },
        { id: 2, displayName: 'Gandalf', email: 'gandalf@firstveil.com', role: 'admin', createdAt: '2023-01-10', lastLogin: '2023-06-24' },
        { id: 3, displayName: 'Frodo', email: 'frodo@firstveil.com', role: 'adventurer', createdAt: '2023-02-05', lastLogin: '2023-06-20' },
        { id: 4, displayName: 'Samwise', email: 'sam@firstveil.com', role: 'adventurer', createdAt: '2023-02-06', lastLogin: '2023-06-21' },
        { id: 5, displayName: 'Gimli', email: 'gimli@firstveil.com', role: 'adventurer', createdAt: '2023-03-10', lastLogin: '2023-06-18' },
        { id: 6, displayName: 'Legolas', email: 'legolas@firstveil.com', role: 'adventurer', createdAt: '2023-03-15', lastLogin: '2023-06-23' },
        { id: 7, displayName: 'Boromir', email: 'boromir@firstveil.com', role: 'commoner', createdAt: '2023-04-10', lastLogin: '2023-06-10' },
        { id: 8, displayName: 'Pippin', email: 'pippin@firstveil.com', role: 'commoner', createdAt: '2023-04-20', lastLogin: '2023-06-15' },
        { id: 9, displayName: 'Merry', email: 'merry@firstveil.com', role: 'commoner', createdAt: '2023-04-21', lastLogin: '2023-06-17' }
      ];
      
      // Simulate network delay
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      setUsers(mockUsers);
    } catch (error) {
      console.error('Error fetching users:', error);
      setErrorMessages(prev => ({ 
        ...prev, 
        users: 'Failed to load users. Please try again.' 
      }));
    } finally {
      setIsLoading(prev => ({ ...prev, users: false }));
    }
  };
  
  const fetchRoleRequests = async () => {
    setIsLoading(prev => ({ ...prev, requests: true }));
    setErrorMessages(prev => ({ ...prev, requests: '' }));
    
    try {
      // Simulated data
      const mockRequests = [
        { 
          id: 1, 
          userId: 7, 
          userName: 'Boromir', 
          email: 'boromir@firstveil.com',
          currentRole: 'commoner',
          requestedRole: 'adventurer',
          reason: 'I wish to join the fellowship and participate in quests to defend Gondor. I bring my sword and shield in service of the realm.',
          date: '2023-06-20'
        },
        {
          id: 2,
          userId: 8,
          userName: 'Pippin',
          email: 'pippin@firstveil.com',
          currentRole: 'commoner',
          requestedRole: 'adventurer',
          reason: 'I want to see mountains again, and then find somewhere quiet where I can finish my book.',
          date: '2023-06-22'
        }
      ];
      
      // Simulate network delay
      await new Promise(resolve => setTimeout(resolve, 1200));
      
      setRoleRequests(mockRequests);
    } catch (error) {
      console.error('Error fetching role requests:', error);
      setErrorMessages(prev => ({ 
        ...prev, 
        requests: 'Failed to load role requests. Please try again.' 
      }));
    } finally {
      setIsLoading(prev => ({ ...prev, requests: false }));
    }
  };
  
  const fetchNotifications = async (silent = false) => {
    if (!silent) {
      setIsLoading(prev => ({ ...prev, notifications: true }));
    }
    setErrorMessages(prev => ({ ...prev, notifications: '' }));
    
    try {
      // Simulated data
      const mockNotifications = [
        {
          id: 1,
          type: 'User Signup',
          content: 'New user Éowyn registered on the platform.',
          date: '2023-06-24',
          read: false
        },
        {
          id: 2,
          type: 'Role Request',
          content: 'Boromir requested a role change from commoner to adventurer.',
          date: '2023-06-20',
          read: false
        },
        {
          id: 3,
          type: 'System Alert',
          content: 'Weekly database backup completed successfully.',
          date: '2023-06-18',
          read: true
        },
        {
          id: 4,
          type: 'User Signup',
          content: 'New user Galadriel registered on the platform.',
          date: '2023-06-15',
          read: true
        }
      ];
      
      // Simulate network delay
      await new Promise(resolve => setTimeout(resolve, 800));
      
      setNotifications(mockNotifications);
    } catch (error) {
      console.error('Error fetching notifications:', error);
      if (!silent) {
        setErrorMessages(prev => ({ 
          ...prev, 
          notifications: 'Failed to load notifications. Please try again.' 
        }));
      }
    } finally {
      if (!silent) {
        setIsLoading(prev => ({ ...prev, notifications: false }));
      }
    }
  };

  const fetchCharacterSheet = async (userId) => {
    try {
      console.log('Fetching character sheet for user ID:', userId);
      
      const response = await fetch(`${API_URL}/api/users/${userId}/character`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      if (!response.ok) {
        // If character data doesn't exist yet, initialize with empty data
        if (response.status === 404) {
          console.log('No character data found for this user yet');
          setCharacterData({
            characterName: '',
            class: '',
            level: '',
            background: '',
            playerName: '',
            race: '',
            alignment: '',
            experiencePoints: '',
            
            abilityScores: {
              strength: '',
              dexterity: '',
              constitution: '',
              intelligence: '',
              wisdom: '',
              charisma: ''
            },
            
            skills: {
              acrobatics: false,
              animalHandling: false,
              arcana: false,
              athletics: false,
              deception: false,
              history: false,
              insight: false,
              intimidation: false,
              investigation: false,
              medicine: false,
              nature: false,
              perception: false,
              performance: false,
              persuasion: false,
              religion: false,
              sleightOfHand: false,
              stealth: false,
              survival: false
            },
            
            armorClass: '',
            initiative: '',
            speed: '',
            hitPointMaximum: '',
            currentHitPoints: '',
            temporaryHitPoints: '',
            hitDice: '',
            
            savingThrows: {
              strength: false,
              dexterity: false,
              constitution: false,
              intelligence: false,
              wisdom: false,
              charisma: false
            },
            
            equipment: '',
            
            featuresAndTraits: '',
            
            proficienciesAndLanguages: '',
            personality: '',
            ideals: '',
            bonds: '',
            flaws: ''
          });
          return;
        }
        throw new Error(`Failed to fetch character sheet: ${response.status}`);
      }
      
      // Parse and set the character data
      const characterData = await response.json();
      setCharacterData(characterData);
      
    } catch (error) {
      console.error('Error fetching character sheet:', error);
      alert('Failed to load character data. Please try again.');
    }
  };

  // Action handlers
  const handleChangeView = (view) => {
    setActiveView(view);
    setIsViewingUserDetails(false);
    setIsComposingMessage(false);
    setSelectedUserId(null);
  };
  
  const handleSelectUser = (userId) => {
    setSelectedUserId(userId);
    setIsViewingUserDetails(true);
    setIsComposingMessage(false);
  };
  
  const handleBackToUserList = () => {
    setIsViewingUserDetails(false);
    setIsComposingMessage(false);
    setSelectedUserId(null);
  };
  
  const handleStartMessageCompose = (userId) => {
    setSelectedUserId(userId);
    setIsComposingMessage(true);
    setIsViewingUserDetails(false);
    setMessageContent('');
    setErrorMessages(prev => ({ ...prev, message: '' }));
  };
  
  const handleCancelMessageCompose = () => {
    setIsComposingMessage(false);
    setIsViewingUserDetails(true);
  };
  
  const handleSendMessage = async () => {
    // Validate message
    if (!messageContent.trim()) {
      setErrorMessages(prev => ({ ...prev, message: 'Message content is required' }));
      return;
    }
    
    try {
      // In a real app, send to the API
      console.log(`Sending message to user ${selectedUserId}: ${messageContent}`);
      
      // Simulate API call delay
      await new Promise(resolve => setTimeout(resolve, 1500));
      
      // On success, go back to user details
      setIsComposingMessage(false);
      setIsViewingUserDetails(true);
      setMessageContent('');
      
      // Show success feedback (in a real app, use a toast notification)
      alert('Message sent successfully');
    } catch (error) {
      console.error('Error sending message:', error);
      setErrorMessages(prev => ({ 
        ...prev, 
        message: 'Failed to send message. Please try again.' 
      }));
    }
  };
  
  const handleRoleChange = async (userId, newRole) => {
    const user = users.find(u => u.id === userId);
    if (!user) return;
    
    try {
      // In a real app, call API to update role
      console.log(`Changing role for user ${userId} from ${user.role} to ${newRole}`);
      
      // Simulate API call delay
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      // Update local state
      setUsers(users.map(u => 
        u.id === userId ? { ...u, role: newRole } : u
      ));
      
      // Show success feedback (in a real app, use a toast notification)
      alert(`Role updated to ${newRole}`);
    } catch (error) {
      console.error('Error updating role:', error);
      alert('Failed to update role. Please try again.');
    }
  };
  
  const handleDeleteUser = (userId) => {
    setActiveModal('delete');
    setModalTargetId(userId);
  };
  
  const handleResetPassword = (userId) => {
    setActiveModal('password');
    setModalTargetId(userId);
  };
  
  const handleApproveRequest = (requestId) => {
    setActiveModal('approve');
    setModalTargetId(requestId);
  };
  
  const handleDenyRequest = (requestId) => {
    setActiveModal('deny');
    setModalTargetId(requestId);
  };
  
  const handleCloseModal = () => {
    setActiveModal(null);
    setModalTargetId(null);
  };
  
  const confirmModalAction = async () => {
    try {
      if (activeModal === 'delete' && modalTargetId) {
        // In a real app, call API to delete user
        console.log(`Deleting user ${modalTargetId}`);
        
        // Simulate API call delay
        await new Promise(resolve => setTimeout(resolve, 1000));
        
        // Update local state
        setUsers(users.filter(u => u.id !== modalTargetId));
        
        // If we're viewing the deleted user, go back to list
        if (selectedUserId === modalTargetId) {
          setIsViewingUserDetails(false);
          setIsComposingMessage(false);
          setSelectedUserId(null);
        }
      }
      else if (activeModal === 'password' && modalTargetId) {
        // In a real app, call API to reset password
        console.log(`Resetting password for user ${modalTargetId}`);
        
        // Simulate API call delay
        await new Promise(resolve => setTimeout(resolve, 1000));
        
        // Show success feedback
        alert('Password has been reset and sent to the user');
      }
      else if (activeModal === 'approve' && modalTargetId) {
        const request = roleRequests.find(r => r.id === modalTargetId);
        if (!request) return;
        
        // In a real app, call API to approve request
        console.log(`Approving role request ${modalTargetId}`);
        
        // Simulate API call delay
        await new Promise(resolve => setTimeout(resolve, 1000));
        
        // Update local state
        setRoleRequests(roleRequests.filter(r => r.id !== modalTargetId));
        
        // Update user role
        setUsers(users.map(u => 
          u.id === request.userId ? { ...u, role: request.requestedRole } : u
        ));
      }
      else if (activeModal === 'deny' && modalTargetId) {
        // In a real app, call API to deny request
        console.log(`Denying role request ${modalTargetId}`);
        
        // Simulate API call delay
        await new Promise(resolve => setTimeout(resolve, 1000));
        
        // Update local state
        setRoleRequests(roleRequests.filter(r => r.id !== modalTargetId));
      }
    } catch (error) {
      console.error('Error performing action:', error);
      alert('Action failed. Please try again.');
    } finally {
      handleCloseModal();
    }
  };
  
  // Helper functions
  const getModalContent = () => {
    if (activeModal === 'delete') {
      const user = users.find(u => u.id === modalTargetId);
      return {
        title: 'Confirm User Deletion',
        message: `Are you sure you want to delete ${user ? user.displayName : 'this user'}? This action cannot be undone.`,
        actionLabel: 'Delete User',
        actionClass: 'danger'
      };
    }
    else if (activeModal === 'password') {
      const user = users.find(u => u.id === modalTargetId);
      return {
        title: 'Reset User Password',
        message: `Are you sure you want to reset the password for ${user ? user.displayName : 'this user'}? A new password will be generated and sent to their email.`,
        actionLabel: 'Reset Password',
        actionClass: 'warning'
      };
    }
    else if (activeModal === 'approve') {
      const request = roleRequests.find(r => r.id === modalTargetId);
      return {
        title: 'Approve Role Request',
        message: `Are you sure you want to approve the role change request for ${request ? request.userName : 'this user'}?`,
        actionLabel: 'Approve Request',
        actionClass: 'success'
      };
    }
    else if (activeModal === 'deny') {
      const request = roleRequests.find(r => r.id === modalTargetId);
      return {
        title: 'Deny Role Request',
        message: `Are you sure you want to deny the role change request for ${request ? request.userName : 'this user'}?`,
        actionLabel: 'Deny Request',
        actionClass: 'danger'
      };
    }
    
    return { title: '', message: '', actionLabel: '', actionClass: '' };
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };
  
  const getInitials = (name) => {
    if (!name) return '?';
    return name.split(' ')
      .map(part => part[0])
      .join('')
      .toUpperCase()
      .substring(0, 2);
  };
  
  // Filtered data
  const filteredUsers = users.filter(user => {
    const query = searchQuery.toLowerCase();
    return (
      user.displayName.toLowerCase().includes(query) ||
      user.email.toLowerCase().includes(query) ||
      user.role.toLowerCase().includes(query)
    );
  });
  
  const selectedUser = users.find(user => user.id === selectedUserId);
  const unreadNotificationsCount = notifications.filter(n => !n.read).length;

  const handleViewCharacterSheet = (userId) => {
    fetchCharacterSheet(userId);
    setIsComposingMessage(false);
    setIsEditingCharacter(true);
  };

  const handleCancelCharacterEdit = () => {
    setIsEditingCharacter(false);
    setCharacterData(null);
  };

  const handleSaveCharacter = async () => {
    try {
      // Make API call to save the character data
      const response = await fetch(`${API_URL}/api/users/${selectedUserId}/character`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}` // Assuming token auth
        },
        body: JSON.stringify(characterData),
      });
      
      if (!response.ok) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }
      
      // Success handling
      alert('Character data saved successfully');
      setIsEditingCharacter(false);
      
    } catch (error) {
      console.error('Error saving character sheet:', error);
      alert(`Failed to save character data: ${error.message}`);
    }
  };

  const handleInputChange = (e, section, field) => {
    if (section) {
      setCharacterData({
        ...characterData,
        [section]: {
          ...characterData[section],
          [field]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
        }
      });
    } else {
      setCharacterData({
        ...characterData,
        [field]: e.target.value
      });
    }
  };

  const calculateModifier = (score) => {
    if (!score) return '';
    const modifier = Math.floor((parseInt(score) - 10) / 2);
    return modifier >= 0 ? `+${modifier}` : `${modifier}`;
  };

  return (
    <div className="admin-dashboard">
      {/* Mobile Navigation Tabs */}
      <nav className="mobile-nav">
        <button 
          className={`nav-tab ${activeView === 'users' ? 'active' : ''}`} 
          onClick={() => handleChangeView('users')}
        >
          <span className="tab-icon">👥</span>
          <span className="tab-label">Users</span>
        </button>
        <button 
          className={`nav-tab ${activeView === 'requests' ? 'active' : ''}`}
          onClick={() => handleChangeView('requests')}
        >
          <span className="tab-icon">🔄</span>
          <span className="tab-label">Requests</span>
          {roleRequests.length > 0 && (
            <span className="notification-badge">{roleRequests.length}</span>
          )}
        </button>
        <button 
          className={`nav-tab ${activeView === 'notifications' ? 'active' : ''}`}
          onClick={() => handleChangeView('notifications')}
        >
          <span className="tab-icon">🔔</span>
          <span className="tab-label">Alerts</span>
          {unreadNotificationsCount > 0 && (
            <span className="notification-badge">{unreadNotificationsCount}</span>
          )}
        </button>
      </nav>
      
      {/* Main Content Area */}
      <main className="dashboard-content">
        {/* Users View */}
        {activeView === 'users' && (
          <div className="view-container">
            {/* User List */}
            {!isViewingUserDetails && !isComposingMessage && (
              <div className="users-list-view">
                <h1 className="view-title">Manage Users</h1>
                
                {errorMessages.users && (
                  <div className="error-message">
                    <p>{errorMessages.users}</p>
                    <button className="retry-button" onClick={fetchUsers}>
                      Retry
                    </button>
                  </div>
                )}
                
                <div className="search-container">
                  <input 
                    type="text"
                    className="search-input"
                    placeholder="Search by name, email or role..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                </div>
                
                {isLoading.users ? (
                  <div className="loading-container">
                    <div className="loading-spinner"></div>
                    <p>Loading users...</p>
                  </div>
                ) : filteredUsers.length === 0 ? (
                  <div className="empty-state">
                    <p>No users match your search.</p>
                  </div>
                ) : (
                  <ul className="users-list">
                    {filteredUsers.map(user => (
                      <li 
                        key={user.id} 
                        className={`user-card ${selectedUserId === user.id ? 'selected' : ''}`}
                        onClick={() => handleSelectUser(user.id)}
                      >
                        <div className="user-avatar">
                          <div className="avatar-content">
                            {getInitials(user.displayName)}
                          </div>
                        </div>
                        <div className="user-info">
                          <h3 className="user-name">{user.displayName}</h3>
                          <p className="user-email">{user.email}</p>
                          <span className={`user-role ${user.role}`}>
                            {user.role.charAt(0).toUpperCase() + user.role.slice(1)}
                          </span>
                        </div>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            )}
            
            {/* User Details */}
            {isViewingUserDetails && selectedUser && (
              <div className="user-details-view">
                <button 
                  className="back-button"
                  onClick={handleBackToUserList}
                >
                  ← Back to Users
                </button>
                
                <div className="user-profile-header">
                  <div className="user-avatar large">
                    <div className="avatar-content">
                      {getInitials(selectedUser.displayName)}
                    </div>
                  </div>
                  <div className="user-title">
                    <h2>{selectedUser.displayName}</h2>
                    <span className={`user-role ${selectedUser.role}`}>
                      {selectedUser.role.charAt(0).toUpperCase() + selectedUser.role.slice(1)}
                    </span>
                  </div>
                </div>
                
                <div className="details-card">
                  <div className="detail-item">
                    <span className="detail-label">Email:</span>
                    <span className="detail-value">{selectedUser.email}</span>
                  </div>
                  <div className="detail-item">
                    <span className="detail-label">Member Since:</span>
                    <span className="detail-value">{formatDate(selectedUser.createdAt)}</span>
                  </div>
                  <div className="detail-item">
                    <span className="detail-label">Last Login:</span>
                    <span className="detail-value">{formatDate(selectedUser.lastLogin)}</span>
                  </div>
                  <div className="detail-item">
                    <span className="detail-label">Role:</span>
                    <div className="role-selector">
                      <select
                        value={selectedUser.role}
                        onChange={(e) => handleRoleChange(selectedUser.id, e.target.value)}
                      >
                        <option value="commoner">Commoner</option>
                        <option value="adventurer">Adventurer</option>
                        <option value="admin">Admin</option>
                      </select>
                    </div>
                  </div>
                </div>
                
                {selectedUser && selectedUser.role === 'adventurer' && (
                  <div className="action-button-container">
                    <button 
                      className="action-button primary"
                      onClick={() => handleViewCharacterSheet(selectedUser.id)}
                    >
                      View Character Sheet
                    </button>
                  </div>
                )}
                
                <div className="user-actions">
                  <button 
                    className="action-button primary"
                    onClick={() => handleStartMessageCompose(selectedUser.id)}
                  >
                    Send Message
                  </button>
                  <button 
                    className="action-button secondary"
                    onClick={() => handleResetPassword(selectedUser.id)}
                  >
                    Reset Password
                  </button>
                  <button 
                    className="action-button danger"
                    onClick={() => handleDeleteUser(selectedUser.id)}
                  >
                    Delete User
                  </button>
                </div>
              </div>
            )}
            
            {/* Message Compose */}
            {isComposingMessage && selectedUser && (
              <div className="message-compose-view">
                <button 
                  className="back-button"
                  onClick={handleCancelMessageCompose}
                >
                  ← Back to User Details
                </button>
                
                <h2 className="compose-title">
                  Message to {selectedUser.displayName}
                </h2>
                
                {errorMessages.message && (
                  <div className="error-message">
                    {errorMessages.message}
                  </div>
                )}
                
                <div className="editor-container">
                  <ReactQuill
                    theme="snow"
                    value={messageContent}
                    onChange={setMessageContent}
                    placeholder="Write your message here..."
                  />
                </div>
                
                <div className="compose-actions">
                  <button 
                    className="action-button primary"
                    onClick={handleSendMessage}
                  >
                    Send Message
                  </button>
                  <button 
                    className="action-button secondary"
                    onClick={handleCancelMessageCompose}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
        
        {/* Role Requests View */}
        {activeView === 'requests' && (
          <div className="view-container">
            <h1 className="view-title">Role Change Requests</h1>
            
            {errorMessages.requests && (
              <div className="error-message">
                <p>{errorMessages.requests}</p>
                <button className="retry-button" onClick={fetchRoleRequests}>
                  Retry
                </button>
              </div>
            )}
            
            {isLoading.requests ? (
              <div className="loading-container">
                <div className="loading-spinner"></div>
                <p>Loading role requests...</p>
              </div>
            ) : roleRequests.length === 0 ? (
              <div className="empty-state">
                <p>No pending role change requests.</p>
              </div>
            ) : (
              <ul className="requests-list">
                {roleRequests.map(request => (
                  <li key={request.id} className="request-card">
                    <div className="request-header">
                      <div className="requester-info">
                        <div className="user-avatar small">
                          <div className="avatar-content">
                            {getInitials(request.userName)}
                          </div>
                        </div>
                        <div>
                          <h3 className="user-name">{request.userName}</h3>
                          <p className="user-email">{request.email}</p>
                        </div>
                      </div>
                      <span className="request-date">
                        {formatDate(request.date)}
                      </span>
                    </div>
                    
                    <div className="request-body">
                      <div className="request-type">
                        Requesting change from <span className={`role-tag ${request.currentRole}`}>{request.currentRole}</span> to <span className={`role-tag ${request.requestedRole}`}>{request.requestedRole}</span>
                      </div>
                      
                      <div className="request-reason">
                        <h4>Reason for Request:</h4>
                        <p>{request.reason}</p>
                      </div>
                    </div>
                    
                    <div className="request-actions">
                      <button 
                        className="action-button success"
                        onClick={() => handleApproveRequest(request.id)}
                      >
                        Approve
                      </button>
                      <button 
                        className="action-button danger"
                        onClick={() => handleDenyRequest(request.id)}
                      >
                        Deny
                      </button>
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </div>
        )}
        
        {/* Notifications View */}
        {activeView === 'notifications' && (
          <div className="view-container">
            <h1 className="view-title">System Notifications</h1>
            
            {errorMessages.notifications && (
              <div className="error-message">
                <p>{errorMessages.notifications}</p>
                <button className="retry-button" onClick={fetchNotifications}>
                  Retry
                </button>
              </div>
            )}
            
            {isLoading.notifications ? (
              <div className="loading-container">
                <div className="loading-spinner"></div>
                <p>Loading notifications...</p>
              </div>
            ) : notifications.length === 0 ? (
              <div className="empty-state">
                <p>No notifications to display.</p>
              </div>
            ) : (
              <ul className="notifications-list">
                {notifications.map(notification => (
                  <li 
                    key={notification.id} 
                    className={`notification-card ${!notification.read ? 'unread' : ''}`}
                  >
                    <div className="notification-header">
                      <span className="notification-type">
                        {notification.type}
                      </span>
                      <span className="notification-date">
                        {formatDate(notification.date)}
                      </span>
                    </div>
                    <p className="notification-content">
                      {notification.content}
                    </p>
                  </li>
                ))}
              </ul>
            )}
          </div>
        )}
      </main>
      
      {/* Modal */}
      {activeModal && (
        <div className="modal-overlay">
          <div className="modal-container">
            <div className="modal-header">
              <h2>{getModalContent().title}</h2>
            </div>
            <div className="modal-body">
              <p>{getModalContent().message}</p>
            </div>
            <div className="modal-footer">
              <button 
                className={`action-button ${getModalContent().actionClass}`}
                onClick={confirmModalAction}
              >
                {getModalContent().actionLabel}
              </button>
              <button 
                className="action-button secondary"
                onClick={handleCloseModal}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Character Sheet Edit Modal */}
      {isEditingCharacter && selectedUser && characterData && (
        <div className="character-sheet-modal">
          <div className="character-sheet-modal-header">
            <h2>Editing Character Sheet for {selectedUser.displayName}</h2>
            <div className="character-sheet-actions">
              <button 
                className="action-button secondary"
                onClick={handleCancelCharacterEdit}
              >
                Cancel
              </button>
              <button 
                className="action-button primary"
                onClick={handleSaveCharacter}
              >
                Save Changes
              </button>
            </div>
          </div>
          
          <div className="character-sheet-container">
            {/* Basic Info Section */}
            <div className="character-section">
              <h3 className="section-title">Basic Information</h3>
              <div className="basic-info-grid">
                <div className="form-group">
                  <label htmlFor="characterName">Character Name</label>
                  <input
                    type="text"
                    id="characterName"
                    value={characterData.characterName}
                    onChange={(e) => handleInputChange(e, null, 'characterName')}
                  />
                </div>
                
                <div className="form-group">
                  <label htmlFor="class">Class</label>
                  <input
                    type="text"
                    id="class"
                    value={characterData.class}
                    onChange={(e) => handleInputChange(e, null, 'class')}
                  />
                </div>
                
                <div className="form-group">
                  <label htmlFor="level">Level</label>
                  <input
                    type="text"
                    id="level"
                    value={characterData.level}
                    onChange={(e) => handleInputChange(e, null, 'level')}
                  />
                </div>
                
                <div className="form-group">
                  <label htmlFor="background">Background</label>
                  <input
                    type="text"
                    id="background"
                    value={characterData.background}
                    onChange={(e) => handleInputChange(e, null, 'background')}
                  />
                </div>
                
                <div className="form-group">
                  <label htmlFor="race">Race</label>
                  <input
                    type="text"
                    id="race"
                    value={characterData.race}
                    onChange={(e) => handleInputChange(e, null, 'race')}
                  />
                </div>
                
                <div className="form-group">
                  <label htmlFor="alignment">Alignment</label>
                  <input
                    type="text"
                    id="alignment"
                    value={characterData.alignment}
                    onChange={(e) => handleInputChange(e, null, 'alignment')}
                  />
                </div>
                
                <div className="form-group">
                  <label htmlFor="experiencePoints">Experience Points</label>
                  <input
                    type="text"
                    id="experiencePoints"
                    value={characterData.experiencePoints}
                    onChange={(e) => handleInputChange(e, null, 'experiencePoints')}
                  />
                </div>
              </div>
            </div>
            
            {/* Ability Scores Section */}
            <div className="character-section">
              <h3 className="section-title">Ability Scores</h3>
              <div className="ability-scores-grid">
                {Object.entries(characterData.abilityScores).map(([ability, score]) => (
                  <div key={ability} className="ability-score-item">
                    <label htmlFor={ability}>{ability.charAt(0).toUpperCase() + ability.slice(1)}</label>
                    <input
                      type="number"
                      id={ability}
                      value={score}
                      onChange={(e) => handleInputChange(e, 'abilityScores', ability)}
                    />
                    <div className="ability-modifier">{calculateModifier(score)}</div>
                  </div>
                ))}
              </div>
            </div>
            
            {/* Saving Throws Section */}
            <div className="character-section">
              <h3 className="section-title">Saving Throws</h3>
              <div className="saving-throws-grid">
                {Object.entries(characterData.savingThrows).map(([ability, isProficient]) => (
                  <div key={ability} className="saving-throw-item">
                    <input
                      type="checkbox"
                      id={`save-${ability}`}
                      checked={isProficient}
                      onChange={(e) => handleInputChange(e, 'savingThrows', ability)}
                    />
                    <label htmlFor={`save-${ability}`}>
                      {ability.charAt(0).toUpperCase() + ability.slice(1)}
                    </label>
                  </div>
                ))}
              </div>
            </div>
            
            {/* Skills Section */}
            <div className="character-section">
              <h3 className="section-title">Skills</h3>
              <div className="skills-grid">
                {Object.entries(characterData.skills).map(([skill, isProficient]) => {
                  // Format skill name (e.g., "sleightOfHand" -> "Sleight of Hand")
                  const formatSkillName = (name) => {
                    return name
                      .replace(/([A-Z])/g, ' $1')
                      .replace(/^./, str => str.toUpperCase());
                  };

                  return (
                    <div key={skill} className="skill-item">
                      <input
                        type="checkbox"
                        id={`skill-${skill}`}
                        checked={isProficient}
                        onChange={(e) => handleInputChange(e, 'skills', skill)}
                      />
                      <label htmlFor={`skill-${skill}`}>
                        {formatSkillName(skill)}
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
            
            {/* Combat Stats Section */}
            <div className="character-section">
              <h3 className="section-title">Combat Statistics</h3>
              <div className="combat-stats-grid">
                <div className="form-group">
                  <label htmlFor="armorClass">Armor Class</label>
                  <input
                    type="text"
                    id="armorClass"
                    value={characterData.armorClass}
                    onChange={(e) => handleInputChange(e, null, 'armorClass')}
                  />
                </div>
                
                <div className="form-group">
                  <label htmlFor="initiative">Initiative</label>
                  <input
                    type="text"
                    id="initiative"
                    value={characterData.initiative}
                    onChange={(e) => handleInputChange(e, null, 'initiative')}
                  />
                </div>
                
                <div className="form-group">
                  <label htmlFor="speed">Speed</label>
                  <input
                    type="text"
                    id="speed"
                    value={characterData.speed}
                    onChange={(e) => handleInputChange(e, null, 'speed')}
                  />
                </div>
                
                <div className="form-group">
                  <label htmlFor="hitPointMaximum">Hit Point Maximum</label>
                  <input
                    type="text"
                    id="hitPointMaximum"
                    value={characterData.hitPointMaximum}
                    onChange={(e) => handleInputChange(e, null, 'hitPointMaximum')}
                  />
                </div>
                
                <div className="form-group">
                  <label htmlFor="currentHitPoints">Current Hit Points</label>
                  <input
                    type="text"
                    id="currentHitPoints"
                    value={characterData.currentHitPoints}
                    onChange={(e) => handleInputChange(e, null, 'currentHitPoints')}
                  />
                </div>
                
                <div className="form-group">
                  <label htmlFor="temporaryHitPoints">Temporary Hit Points</label>
                  <input
                    type="text"
                    id="temporaryHitPoints"
                    value={characterData.temporaryHitPoints}
                    onChange={(e) => handleInputChange(e, null, 'temporaryHitPoints')}
                  />
                </div>
                
                <div className="form-group">
                  <label htmlFor="hitDice">Hit Dice</label>
                  <input
                    type="text"
                    id="hitDice"
                    value={characterData.hitDice}
                    onChange={(e) => handleInputChange(e, null, 'hitDice')}
                  />
                </div>
              </div>
            </div>
            
            {/* Features & Traits Section */}
            <div className="character-section">
              <h3 className="section-title">Features & Traits</h3>
              <div className="form-group">
                <textarea
                  id="featuresAndTraits"
                  value={characterData.featuresAndTraits}
                  onChange={(e) => handleInputChange(e, null, 'featuresAndTraits')}
                  placeholder="Features, traits, and abilities..."
                ></textarea>
              </div>
            </div>
            
            {/* Proficiencies & Languages Section */}
            <div className="character-section">
              <h3 className="section-title">Proficiencies & Languages</h3>
              <div className="form-group">
                <textarea
                  id="proficienciesAndLanguages"
                  value={characterData.proficienciesAndLanguages}
                  onChange={(e) => handleInputChange(e, null, 'proficienciesAndLanguages')}
                  placeholder="Weapons, armor, tools, languages..."
                ></textarea>
              </div>
            </div>
            
            {/* Character Details Section */}
            <div className="character-section">
              <h3 className="section-title">Character Details</h3>
              <div className="character-details-grid">
                <div className="form-group">
                  <label htmlFor="personality">Personality Traits</label>
                  <textarea
                    id="personality"
                    value={characterData.personality}
                    onChange={(e) => handleInputChange(e, null, 'personality')}
                    placeholder="Character traits..."
                  ></textarea>
                </div>
                
                <div className="form-group">
                  <label htmlFor="ideals">Ideals</label>
                  <textarea
                    id="ideals"
                    value={characterData.ideals}
                    onChange={(e) => handleInputChange(e, null, 'ideals')}
                    placeholder="Character ideals..."
                  ></textarea>
                </div>
                
                <div className="form-group">
                  <label htmlFor="bonds">Bonds</label>
                  <textarea
                    id="bonds"
                    value={characterData.bonds}
                    onChange={(e) => handleInputChange(e, null, 'bonds')}
                    placeholder="Character bonds..."
                  ></textarea>
                </div>
                
                <div className="form-group">
                  <label htmlFor="flaws">Flaws</label>
                  <textarea
                    id="flaws"
                    value={characterData.flaws}
                    onChange={(e) => handleInputChange(e, null, 'flaws')}
                    placeholder="Character flaws..."
                  ></textarea>
                </div>
              </div>
            </div>
            
            {/* Equipment Section */}
            <div className="character-section">
              <h3 className="section-title">Equipment</h3>
              <div className="form-group">
                <textarea
                  id="equipment"
                  value={characterData.equipment}
                  onChange={(e) => handleInputChange(e, null, 'equipment')}
                  placeholder="Weapons, armor, and other equipment..."
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminDashboard; 